a {
  text-decoration: none;
  color: $color-text-default;
}
blockquote {
  margin: 0 0 $line-spacing-default 0;
  font-size: $font-size-small;
  padding: 0 $line-spacing-default;
}
cite {
  font-style: normal;
  font-size: $font-size-small;
  font-family: #{$font-family-body-sc};
  // letter-spacing: $letter-spacing-loose;
  // text-transform: lowercase;
  i {
    text-transform: none;
    font-family: #{$font-family-body};
    letter-spacing: 0;
    font-style: italic;
  }
}
figure {
  margin: 0 0 $line-spacing-default 0;
  max-width: 100%;
}
figcaption,
.caption {
  display: block;
  margin: -($line-spacing-default - 0.5rem) 0 0 0;
  color: $color-text-light;
  font-size: $font-size-smaller;
  text-align: center;
  a {
    color: $color-text-light;
  }
}
img {
  margin: $line-spacing-default 0;
  max-width: 100%;
  margin: $line-spacing-default auto;
  display: block;
}
h1,
h2,
h3,
h4 {
  text-align: center;
  margin: $line-spacing-default 0;
  font-weight: normal;
  font-style: normal;
}
article {
  h2:first-child,
  h3:first-child,
  h4:first-child,
  h5:first-child,
  h6:first-child {
    margin-top: 0;
  }
}
h1 {
  font-size: $font-size-larger;
  line-height: $line-height-tighter;
  margin-bottom: $line-spacing-larger;
  letter-spacing: $letter-spacing-tight;
  font-feature-settings: "liga", "dlig", "hlig", "tnum", "cv11";
  // font-family: #{$font-family-display};
  // font-style: italic;
}
h1+h2 {
  margin-top: -$line-spacing-large;
}
h2 {
  font-size: $font-size-large;
  font-family: #{$font-family-body-sc};
  // font-feature-settings: "smcp", "c2sc";
  // letter-spacing: $letter-spacing-loose;
  line-height: $line-height-default;
  text-transform: lowercase;
}
h3 {
  font-weight: 400;
  font-style: italic;
  font-size: $font-size-large;
}
h4,
h5,
h6 {
  font-weight: normal;
  font-size: 1rem;
}
hr {
  height: $line-spacing-default * 2;
  margin: 0;
  border: 0;
  text-align: center;
  color: $color-line-light;
}
p {
  margin: 0;
}
p+p {
  text-indent: $line-spacing-large;
}
article > p {
  position: relative;
}
code {
  font-family: #{$font-family-mono};
  font-size: $font-size-small;
}
pre {
  font-family: #{$font-family-mono};
  line-height: $line-height-tight;
  background-color: $color-background-light;
  padding: $line-spacing-default;
  margin: $line-spacing-default (-$line-spacing-default);
  overflow-x: scroll;
  code {
    color: $color-text-light;
    font-size: 0.84rem;
  }
}
strong,
.first-word {
  font-weight: normal;
  // font-feature-settings: "smcp";
  // letter-spacing: $letter-spacing-loose;
  font-family: #{$font-family-body-sc};
}

em {
  font-style: italic;
}
sup,
sub {
  font-size: $font-size-smaller;
  line-height: 0;
  padding-left: 2px;
  a {
    background-color: transparent !important;
    background-image: none !important;
    color: $color-brand !important;
    &:hover {
      color: $color-text-default !important;
    }
  }
}
table {
  margin: 0 0 $line-spacing-default 0;
}


ul,
ol {
  margin: 0 0 $line-spacing-default 0;
  padding: 0;
  li {
    margin: 0 $line-spacing-large;
  }
  ul,
  ol {
    margin: 0;
  }
}
ul {
  list-style-type: disc;
}
/* Footnotes */
article hr + ol:last-child, article hr + ul:last-child, .footnotes {
  font-size: $font-size-smaller;
  word-wrap: break-word;
  margin-top: $line-spacing-large;
  margin-bottom: 0;
  line-height: $line-height-tight;
  .reversefootnote {
    background: none;
    color: $color-brand;
    &:hover {
      background: none;
      color: $color-text-default;
    }
  }
}
