$width-content-max: 580px;
$width-nav-max: 810px;

$font-size-ui: 0.52rem;
$font-size-default: 23px;
$font-size-small: 0.9rem;
$font-size-smaller: 0.72rem;
$font-size-tiny: 0.6rem;
$font-size-large: 1.15rem;
$font-size-larger: 1.9rem;

$font-family-display: "content-display, Gill Sans, Gill Sans Nova, Gill Sans MT, sans-serif";
$font-family-body: "content-body, Times New Roman, Times, serif";
$font-family-body-sc: "content-body-sc, Times New Roman, Times, serif";
$font-family-system: "'-apple-system', '.SFNSText-Regular', 'San Francisco', Roboto, 'Segoe UI', 'Helvetica Neue', 'Lucida Grande', sans-serif";
$font-family-mono: "content-mono, monospace";

$line-height-default: 1.4;
$line-height-tight: 1.3;
$line-height-tighter: 1.2;
$line-height-hairline: 1.1;
$line-height-loose: 1.8;

$line-spacing-small: 0.4rem;
$line-spacing-default: 1.4rem;
$line-spacing-large: 2rem;
$line-spacing-larger: 5rem;

$screen-small: "screen and (max-width: 560px)";
$screen-medium: "screen and (max-width: 682px)";

$letter-spacing-tighter: -0.03em;
$letter-spacing-tight: -0.01em;
$letter-spacing-loose: 0.05em;
$letter-spacing-looser: 0.17em;
