@import "fonts";
@import "animations";
@import "colors";
@import "typography";
@import "formatting";

html {
  font-size: $font-size-default;
  font-family: #{$font-family-body};
  line-height: $line-height-default;
  min-height: 100%;
  color: $color-text-default;
  background-color: $color-background-default;
  font-feature-settings: "liga", "calt", "tnum", "cv11";

  // @media screen and (max-width: 600px) {
  //   font-size: $font-size-default - 1px;
  // }
  @media screen and (max-width: 550px) {
    font-size: $font-size-default - 1px;
  }
  @media screen and (max-width: 375px) {
    font-size: $font-size-default - 2px;
  }
  @media screen and (max-width: 360px) {
    font-size: $font-size-default - 3px;
  }
}

body {
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;

  // border-left: solid 3vw $color-background-secondary;
  // border-right: solid 3vw $color-background-secondary;
  margin: 0;
  padding: 0;
  font-size: 100%;
  min-height: 100%;
  transition-property: transform;
  transition-duration: $duration-default;

  @media screen and (max-width: 840px) {
    border-left: 0;
    border-right: 0;
  }
  &.is-home {
    // -webkit-font-smoothing: antialiased;
    // -moz-osx-font-smoothing: grayscale;
    background-color: $color-background-secondary;

    // background-image: url(/images/bg.svg);
    // background-repeat: no-repeat;
    // background-size: 100% auto;
    // background-position: 0 0;
    // padding-top: 45vw;

    footer {
      background: none;
    }
  }

}

#hero {
  background-color: $color-brand;
}
#hero img {
  max-width: $width-content-max - 100px;
  max-height: auto;
  width: 100%;
  height: 100%;
  margin: 0 auto;
}
// #title-wrapper {
//   background-image: url(/images/bg.svg);
//   background-repeat: no-repeat;
//   background-size: 100% auto;
//   background-position: 0 0;
//   padding-top: 45vw;
// }
#title {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: $color-background-default;
  text-align: center;
  max-width: $width-content-max;
  font-size: 1.29rem;
  max-width: $width-content-max;
  margin: 0 auto $line-spacing-larger auto;
  padding: 8vh $line-spacing-default 0 $line-spacing-default;
  line-height: $line-height-hairline;

}
#title-author {
  font-family: #{$font-family-display};
  font-size: 1.45rem;
  font-weight: 500;
  line-height: $line-height-default;
  margin-bottom: 0.2rem;
}
#title-translator {
  font-size: $font-size-smaller;
  color: $color-text-overlay;
  strong {
    text-transform: lowercase;
  }
}
#title-book {
  margin-bottom: 0.7rem;
  font-style: italic;
}

#previous-next {
  font-size: $font-size-small;
  text-align: center;
  padding-bottom: $line-spacing-larger;
  max-width: $width-content-max;
  margin: 0 auto;
  position: relative;
  z-index: 8;
  margin-top: -$line-spacing-larger;
  padding-top: $line-spacing-large;
  a {
    color: $color-brand;
    font-weight: 500;
    font-family: #{$font-family-display};
    display: inline-block;
    padding: 1rem;
    border-radius: 2px;
    // color: $color-background-default;
    // background-color: $color-brand;
    // font-weight: 500;
    // font-family: #{$font-family-display};
    // display: inline-block;
    // padding: 0.5em 0.8em;
    // border-radius: 4px;
    // position: relative;
    // box-shadow: 0 3px 0 rgb(150,68,66), 0 3px 6px rgba(0,0,0,0.15), 0 10px 15px rgba(199,85,85,0.1);
    // &:active {
    //   top: 2px;
    //   box-shadow: 0 1px 0 darken($color-brand, 10%), 0 1px 3px rgba(0,0,0,0.15);
    // }
    &:hover {
      border-color: $color-text-default;
      color: $color-text-default;
    }
  }
}

#nav-link {
  position: fixed;
  top: 1rem;
  left: 1rem;
  height: 2rem;
  width: 2rem;
  background: $color-text-default;
  color: $color-background-default;
  text-align: center;
  line-height: 2rem;
  cursor: pointer;
  z-index: 100;
  border: solid 2px $color-background-default;
}
#nav {
  display: none;
  background-color: $color-text-default;
  font-size: $font-size-smaller;
  position: fixed;
  top: 0;
  left: 0;
  width: 280px;
  height: 100%;
  box-sizing: border-box;
  padding: $line-spacing-large;
  li {
    margin: 0 0 0 $line-spacing-default;
  }
  h3 {
    margin-top: 0;
    font-size: $font-size-small;
  }
}

aside {
  color: $color-text-overlay;
  margin-top: $line-spacing-larger;
  text-align: center;
  font-size: $font-size-smaller;
  line-height: $line-height-tight;
  p {
    margin-bottom: $line-spacing-default;
    text-indent: 0;
  }
  img {
    max-width: 50px;
    height: auto;
    position: relative;
    right: -5px;
    opacity: 0.15;
  }
}
#contents {
  text-align: center;
  padding-bottom: $line-spacing-large;
  h2:first-child {
    position: relative;
    margin-top: 0;
    top: -0.7em;
    padding: 0 0.7em;
    display: inline-block;
    background: $color-background-secondary;
    margin-bottom: 0;
    color: $color-text-overlay;
  }
}

.cover-shadow-1,
.cover-shadow-2,
.cover-shadow-3,
.cover-highlight-1,
.cover-highlight-2,
.cover-highlight-3,
.cover-highlight-4 {
  position: absolute;
  height: 100%;
  top: 0;
  z-index: 10;
  height: 100%;
}
.cover-shadow-1 {
  border-radius: 4px 0 0 4px;
  left: 0;
  width: 1.5%;
  background-image: linear-gradient(to right, rgba(0,0,0,0.8) 0, rgba(0,0,0,0.2) 95%, rgba(0,0,0,0) 100%);
  // background-image: radial-gradient(ellipse at center top, red 0, blue 150%);
}
.cover-shadow-2 {
  left: 1.8%;
  width: 1.8%;
  background-image: linear-gradient(to left, rgba(0,0,0,0.15), rgba(0,0,0,0));
}
.cover-shadow-3 {
  left: 3.6%;
  width: 1%;
  background-image: linear-gradient(to right, rgba(0,0,0,0.15), rgba(0,0,0,0));
}
.cover-highlight-1 {
  top: 0.1%;
  left: 1.4%;
  width: 0.7%;
  background-image: radial-gradient(ellipse at center top, rgba(255,255,255,0.08) 0, rgba(255,255,255,0) 70%);
}
.cover-highlight-2 {
  top: 0.15%;
  left: 1.5%;
  width: 1.5%;
  background-image: radial-gradient(ellipse at center top, rgba(255,255,255,0.05) 0, rgba(255,255,255,0) 100%);
}
.cover-highlight-3 {
  top: 0.15%;
  left: 4.4%;
  width: 0.8%;
  background-image: radial-gradient(ellipse at center top, rgba(255,255,255,0.04) 0, rgba(255,255,255,0) 100%);
}
.cover-highlight-4 {
  top: 0.15%;
  left: 3.3%;
  width: 2.5%;
  background-image: radial-gradient(ellipse at center top, rgba(255,255,255,0.1) 0, rgba(255,255,255,0) 20%);
}
.cover-highlight-5 {
  position: absolute;
  top: 0.1%;
  left: 3.7%;
  width: 1.6%;
  height: 0.4%;
  z-index: 10;
  background-image: radial-gradient(ellipse at center center, rgba(255,255,255,0.1) 0, rgba(255,255,255,0) 80%);
}
.cover-highlight-6 {
  position: absolute;
  top: 0.1%;
  left: 0.9%;
  width: 1.4%;
  height: 0.4%;
  z-index: 10;
  background-image: radial-gradient(ellipse at center center, rgba(255,255,255,0.1) 0, rgba(255,255,255,0) 80%);
}
.cover-shadow-top {
  position: absolute;
  width: 100%;
  z-index: 10;
  left: 0.7%;
  top: 0;
  height: 0.15%;
  background-image: linear-gradient(to bottom, rgba(0,0,0,0.3), rgba(0,0,0,0));
}
.cover-shadow-bottom {
  position: absolute;
  width: 100%;
  z-index: 10;
  left: 0.7%;
  bottom: 0;
  height: 0.3%;
  background-image: linear-gradient(to top, rgba(0,0,0,0.4), rgba(0,0,0,0));
}
.cover-highlight-top {
  position: absolute;
  width: 100%;
  z-index: 10;
  left: 1.5%;
  top: 0.1%;
  height: 0.3%;
  background-image: linear-gradient(to bottom, rgba(255,255,255,0.1), rgba(255,255,255,0));
}
.cover-highlight-right {
  position: absolute;
  width: 0.3%;
  height: 100%;
  z-index: 10;
  top: 0;
  right: 0;
  background-image: linear-gradient(to left, rgba(255,255,255,0.05), rgba(255,255,255,0));
}
.cover-bloom {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: radial-gradient(circle at center top, rgba(255,255,255,0.05) 0, rgba(255,255,255,0) 30%);
  z-index: 10;
  background-attachment: fixed;
}

.cover {
  padding: 0 2rem 2rem 2rem;
  position: relative;
  -webkit-user-select: none;
  -mozuser-select: none;
  user-select: none;
}
#cover {
  padding: 2rem;
  position: relative;
  -webkit-user-select: none;
  -mozuser-select: none;
  user-select: none;
  background-image: radial-gradient(ellipse at center top, rgb(75,75,85) 0, $color-background-secondary 100%);
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to top, $color-background-secondary, rgba(30,30,40,0));
  }
}
.cover-effects {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 4px 3px 3px 4px;
  overflow: hidden;
}
.cover-image {
  position: relative;
  max-width: $width-content-max / 1.5;
  width: auto;
  height: auto;
  margin: 0 auto;
  padding: 0;
  border-radius: 4px 3px 3px 4px;
  box-shadow: 0 25px 100px rgba(0,0,0,0.25), 0 40px 40px rgba(0,0,0,0.15), 0 4px 10px rgba(0,0,0,0.25);
  // box-shadow: 0 -150px 100px rgba(0,0,0,0.1), 0 -80px 50px rgba(0,0,0,0.2), 0 -50px 1px rgba(0,0,0,0.2);
  img {
    z-index: 6;
    position: relative;
    border-radius: 4px 3px 3px 4px;
    margin: 0;
    padding: 0;
    width: 100%;
    height: auto;
  }
  &:after {
    border-radius: 4px 3px 3px 4px;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    background-image: radial-gradient(circle at top center, rgba(255,255,255,0.05) 0, rgba(255,255,255,0) 100%);
    z-index: 7;
    position: absolute;
    top: 0;
    left: 0;

      // background-color: #111;
  }
}
.cover-share {
  position: absolute;
  top: 3rem;
  right: -1.75rem;
  width: 2rem;
  z-index: 5;
  &:before {
    z-index: 6;
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 0.3rem;
    height: 100%;
    background-image: linear-gradient(to right, rgba(0,0,0,1), rgba(0,0,0,0));
  }
  a {
    position: relative;
    display: block;
    height: 0.8rem;
    padding: 0.3rem 0 0.7em 0;
    width: 1.8rem;
    padding-left: 0.25rem;
    text-align: center;
    margin-bottom: 0.25rem;
    box-shadow: 0 10px 20px rgba(0,0,0,0.2), inset 0 1px 1px rgba(255,255,255,0.1);
    transition-duration: $duration-default;
    border-radius: 0 1px 1px 0;
    transform: translate3d(0, 0, 0);
    svg {
      fill: $color-background-default;
      max-width: 0.75rem;
      height: auto;
    }
    &:hover {
      transform: translate3d(0.23rem, 0, 0);
      &:after {
        transform: translate3d(-0.23rem, 0, 0);
      }
    }
    &:before {
      z-index: 6;
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgb(150,150,150);
      opacity: 0.08;
    }
    &:after {
      transition-duration: $duration-default;
      z-index: 6;
      content: "";
      display: block;
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-image: linear-gradient(to right, rgba(0,0,0,0.25) 10%, rgba(0,0,0,0));
    }
  }
}
.share-pinterest {
  background-color: rgb(65,65,80);
}
.share-facebook {
  background-color: rgb(65,65,80);
}
.share-twitter {
  background-color: rgb(65,65,80);
}
.buy-links {
  max-width: $width-content-max;
  margin: 0 auto;
  text-align: center;
  a {
    b {
      font-weight: normal;
      margin-left: 0.25em;
      color: rgba(0,0,0,0.5);
    }
    span {
      position: relative;
    }
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    margin: 0.8em 0.4em;
    user-select: none;
    display: inline-block;
    // background-color: $color-brand;
    // background-image: linear-gradient(to top, darken($color-brand, 2%), lighten($color-brand, 2%));
    // text-shadow: 0 1px 0 rgba(0,0,0,0.1);
    color: $color-background-default;
    font-family: #{$font-family-display};
    font-size: $font-size-tiny;
    padding: 0.7em 1em;
    font-weight: 700;
    text-align: center;
    position: relative;
    border-radius: 3px;
    background-color: $color-brand;
    background-image: linear-gradient(to top, darken($color-brand, 5%), $color-brand);
    box-shadow: inset 0 -1px 0 rgba(0,0,0,0.7), inset 0 1px 0 rgba(255,255,255,0.1), inset -1px 0 0 rgba(255,255,255,0.05), inset 1px 0 0 rgba(255,255,255,0.05), 0 2px 4px rgba(0,0,0,0.3), 0 1px 16px rgba(180,70,75,0.14);
  }
  a:hover {
    color: $color-background-default;
  }
  a:active {
    span {
      top: 1px;
    }
    background-color: darken($color-brand, 5%);
    background-image: linear-gradient(to top, darken($color-brand, 10%), darken($color-brand, 5%));
    box-shadow: inset 0 1px 1px rgba(0,0,0,0.4), inset 0 0 2px rgba(0,0,0,0.2);
  }
}
.buy-blurb {
  max-width: $width-content-max / 1.5;
  font-size: $font-size-smaller;
  margin: 0 auto;
  padding: 1rem 2rem;
  color: $color-text-overlay;
  a {
    color: $color-background-default;
    &:hover {
      color: $color-brand;
    }
  }
}
.read-online {
  color: $color-text-overlay;
  font-size: $font-size-tiny;
  font-family: #{$font-family-display};
  max-width: $width-content-max / 1.75;
  margin: 0 auto;
  padding-top: 1rem;
}


#site-title {
  display: block;
  width: $width-content-max / 3;
  margin: 0 auto;
  @media #{$screen-small} {
    padding: 1.5rem 0 0 0;
    margin-top: 2rem;
  }
}
#menu-link {
  position: absolute;
  top: 0.2rem;
  left: 1rem;
  font-size: 24px;
  width: 36px;
  height: 36px;
  line-height: 36px;
  &:hover {
    cursor: pointer;
    #menu-icon,
    #menu-icon:before,
    #menu-icon:after {
      background-color: $color-brand;
    }
  }
  @media #{$screen-small} {
    width: 3rem;
    height: 3rem;
    line-height: 1.8rem;
    left: 0;
    top: 0;
  }
}
#menu-icon {
  display: inline-block;
  position: relative;
  top: 0;
  height: 1px;
  width: 14px;
  background-color: $color-text-light;
  line-height: 0;
  &:before,
  &:after {
    content: "";
    display: block;
    height: 1px;
    width: 14px;
    background-color: $color-text-light;
    transition-duration: $duration-short;
    transition-property: transform background-color;
  }
  &:before {
    position: relative;
    top: -5px;
    transform: rotate(0);
  }
  &:after {
    position: relative;
    top: 4px;
    transform: rotate(0);
  }
}
.is-showing-menu #menu-icon {
  background-color: rgba(0,0,0,0) !important;
  &:before {
    transform: rotate(45deg) translate3d(3px, 4.2px, 0);
  }
  &:after {
    transform: rotate(-45deg) translate3d(3px, -4.2px, 0);
  }
}
#subscription-link {
  display: inline-block;
  position: absolute;
  top: 0.8rem;
  right: 1rem;
  height: 32px;
  line-height: 32px;
  border-radius: 32px;
  padding: 0 16px;
  cursor: pointer;
  font-size: 26px;
  color: $color-text-light;
  span {
    transition-duration: $duration-short;
    transition-property: opacity;
  }
  &:hover {
    color: $color-brand;
    #subscription-icon:before,
    #subscription-icon:after {
      background-color: $color-brand;
    }
  }
  @media #{$screen-small} {
    right: 0;
    top: 0.45rem;
  }
}
#subscription-icon {
  display: inline-block;
  margin-left: 0.3rem;
  position: relative;
  top: -2px;
  &:before,
  &:after {
    content: "";
    display: block;
    height: 2px;
    width: 14px;
    background-color: $color-text-default;
    transition-duration: $duration-short;
    transition-property: transform;
    border-radius: 2px;
  }
  &:before {
    transform: rotate(0);
  }
  &:after {
    position: relative;
    top: -2px;
    transform: rotate(90deg);
  }
}
.is-showing-subscription #subscription-icon {
  &:before {
    transform: rotate(-45deg);
  }
  &:after {
    transform: rotate(45deg);
  }
}


article a,
#latest a,
#subscription a,
.underlined {
  text-decoration: none;
  // text-shadow: -1px -1px 0 $color-background-default, 1px -1px 0 $color-background-default, 1px 0 0 $color-background-default,  0 1px 0 $color-background-default, -1px 1px 0 $color-background-default, 1px 1px 0 $color-background-default;
  color: $color-brand;
  // background-position: 0 -0.1em;
  // background-image: linear-gradient(to top, $color-text-light, $color-text-light 1px, rgba(0, 0, 0, 0) 1px, rgba(0, 0, 0, 0));
  &:hover {
    color: $color-text-default;
    // background-image: linear-gradient(to top, $color-brand, $color-brand 1px, rgba(0, 0, 0, 0) 1px, rgba(0, 0, 0, 0));
  }
}
// article a em,
// article em a {
//   background-position: 0 -0.42em;
// }
// footer a {
//   background-position: 0 -0.02em;
//   // background-image: linear-gradient(to top, rgba(255,255,255,0.2), rgba(255,255,255,0.2) 1px, rgba(0, 0, 0, 0) 1px, rgba(0, 0, 0, 0));
//   text-shadow: -1px -1px 0 $color-background-secondary, 1px -1px 0 $color-background-secondary, 1px 0 0 $color-background-secondary,  0 1px 0 $color-background-secondary, -1px 1px 0 $color-background-secondary, 1px 1px 0 $color-background-secondary;
// }
footer a:hover {
  color: $color-brand;
  // background-image: linear-gradient(to top, $color-brand, $color-brand 1px, rgba(0, 0, 0, 0) 1px, rgba(0, 0, 0, 0));
}
footer h2 {
  font-size: $font-size-small;
  font-style: italic;
  font-family: #{$font-family-body};
  text-transform: none;
  letter-spacing: 0;
}
footer ul {
  list-style-type: none;
  li {
    // font-feature-settings: "smcp", "c2sc";
    letter-spacing: $letter-spacing-looser;
    font-family: #{$font-family-display};
    text-transform: uppercase;
    font-size: $font-size-tiny;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: $line-spacing-large;
    margin-top: 0;
    font-weight: 700;
    line-height: 1.7;
    div {
      font-weight: normal;
      line-height: $line-height-tight;
      font-family: #{$font-family-body};
      font-feature-settings: "liga", "calt", "tnum", "cv11";
      letter-spacing: 0;
      font-size: $font-size-smaller;
      color: $color-text-overlay;
      text-transform: none;
    }
  }
}
.chapter-nav-link {
  letter-spacing: 0;
  font-feature-settings: normal;
  font-size: $font-size-smaller;
  // font-family: #{$font-family-body-sc};
  // text-transform: lowercase;
    // font-feature-settings: "smcp", "c2sc";
  // letter-spacing: $letter-spacing-loose;
  cursor: pointer;
  border-radius: 3px;
  color: $color-background-default;
  display: inline-block;
  padding: 0 6px 2px 5px;
  &:hover {
    color: $color-brand;
    &:before {
      color: $color-brand;
    }
  }
  &:before {
    content: "▸";
    position: relative;
    top: 0.5px;
    margin-right: 0.3em;
    color: $color-text-overlay;
  }
}
.chapter-nav-inner {
  display: none;
  line-height: 1.8;
  margin-top: 0.4em;
  // font-feature-settings: "smcp", "c2sc";
  // letter-spacing: $letter-spacing-loose;
  font-family: #{$font-family-body-sc};
  text-transform: lowercase;
}
.chapter-nav.is-showingChapterNav {
  .chapter-nav-inner {
    display: block;
  }
  .chapter-nav-link {
    &:before {
      content: "▾";
    }
  }
}

// article ::selection {
//   text-shadow: none;
//   background-color: $color-brand;
//   color: $color-background-default;
// }
// article ::-moz-selection {
//   text-shadow: none;
//   background-color: $color-brand;
//   color: $color-background-default;
// }

article {
  padding-top: $line-spacing-larger;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: $line-spacing-larger;
  max-width: $width-content-max;
  hanging-punctuation: first;
}


#post {
  margin: 0 auto;
  position: relative;
  max-width: $width-content-max;
  z-index: 8;
  // opacity: 0;
  &.is-meta {
    color: $color-background-default;
  }
}

header {
  -webkit-user-select: none;
  -mozuser-select: none;
  user-select: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9;
  background-color: $color-background-default;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  // color: $color-background-default;
  margin: 0 auto;
  // opacity: 0;
  height: 3rem;
  line-height: 3rem;
  text-align: center;
  div {
    margin: 0 auto;
    padding: 0 1rem;
    z-index: 100;
    position: relative;
  }
  #contents-link {
    float: left;
    font-family: #{$font-family-system};
    color: $color-brand;
    fill: $color-brand;
    position: relative;
    top: 0.4rem;
    &:hover {
      fill: $color-text-default;
      color: $color-text-default;
    }
  }
  strong {
    // font-feature-settings: "c2sc", "smcp";
    cursor: default;
    font-feature-settings: normal;
    font-family: #{$font-family-display};
    text-transform: uppercase;
    letter-spacing: $letter-spacing-looser;
    font-size: $font-size-ui;
    font-style: normal;
    position: relative;
    z-index: 101;
    cursor: pointer;
    font-weight: 700;
    color: $color-brand;
    &:hover {
      color: $color-text-default;
    }
  }
  h1 {
    margin-top: 0;
  }
  h1,
  h2 {
    margin-bottom: 0;
    position: relative;
  }
  a {
    font-size: $font-size-smaller;
  }
}
#post-meta {
  text-align: center;
  position: relative;
  max-width: $width-content-max;
  margin: 0 auto $line-spacing-larger auto;
  padding: 0 1rem;
  line-height: $line-height-tighter;

  .author-picture {
    position: absolute;
    top: 2px;
    left: 0;
    margin: 0;
    display: block;
    width: 50px;
    height: 50px;
    background: $color-link;
    border-radius: 40px;
    // TODO left padding for small screen
  }
  .author {
    font-size: $font-size-small;
  }
  .date {
    color: $color-text-light;
    font-size: $font-size-ui;
  }
}



#mc-embedded-subscribe-form {
  text-align: center;
  input[type=email] {
    border: 0;
    border-bottom: solid 1px $color-text-overlay;
    padding: 0.4rem 0;
    font-family: #{$font-family-body};
    font-size: $font-size-smaller;
    font-style: italic;
    text-align: center;
    background: transparent;
    color: $color-text-overlay;
    &:hover {
      border-color: $color-background-default;
    }
    &:focus {
      border-color: $color-background-default;
      outline: none;
      color: $color-background-default;
    }
  }
  input[type=submit] {
    border: 0;
    padding: 0.4rem 0.7rem;
    font-family: #{$font-family-body};
    // font-feature-settings: "smcp", "tnum", "c2sc";
    font-family: #{$font-family-display};
    font-weight: normal;
    text-align: center;
    font-size: $font-size-smaller !important;
    font-weight: 500;
    color: $color-background-default;
    border-radius: 3px;
    background-color: $color-brand;
    background-image: linear-gradient(to top, darken($color-brand, 3%), lighten($color-brand, 3%));
    box-shadow: 0 3px 0 darken($color-brand, 10%), 0 3px 8px rgba(0,0,0,0.3);
    position: relative;
    &:hover {
      cursor: pointer;
      background-image: linear-gradient(to top, darken($color-brand, 0%), lighten($color-brand, 6%));
      color: $color-background-default;
    }
    &:active {
      top: 2px;
      box-shadow: 0 1px 0 darken($color-brand, 10%), 0 1px 3px rgba(0,0,0,0.2);
    }

  }
}

#newsletter-wrapper {
  padding: $line-spacing-default 0;
  input[type=submit],
  input[type=email] {
    border-color: $color-line-light;
    color: $color-text-default;
    font-size: $font-size-small;
    &:hover {
      color: $color-text-default;
      border-color: $color-text-default;
    }
    &:focus {
      color: $color-text-default;
      border-color: $color-text-default;
    }
  }
  @media #{$screen-small} {
    padding: $line-spacing-default 0;
  }
}
#newsletter {
  max-width: $width-content-max;
  margin: 0 auto;
}
#newsletter-description {
  font-size: $font-size-smaller;
  -webkit-column-count: 2;
  -moz-column-count: 2;
  -ms-column-count: 2;
  column-count: 2;
  -webkit-column-gap: $line-spacing-default;
  -moz-column-gap: $line-spacing-default;
  -ms-column-gap: $line-spacing-default;
  column-gap: $line-spacing-default;
  margin: 0 $line-spacing-default;
  p+p {
    text-indent: 0;
  }
}
#latest {
  text-align: center;
  padding: $line-spacing-default 0;
}
@media print {
  html {
    color: rgb(0,0,0);
    font-size: $font-size-small;
    line-height: $line-height-tight;
  }
  body {
    margin: 0 auto;
    max-width: 30em;
    border: 0;
  }
  sup a,
  .reversefootnote,
  header,
  header a,
  header strong {
    color: rgb(0,0,0) !important;
  }
  #post {
    opacity: 1;
  }
  #newsletter,
  footer,
  nav,
  aside,
  #previous-next,
  #show-bookmarks,
  #contents-link {
    display: none !important;
  }
  hr:before {
    color: rgb(0,0,0);
  }
}



strong:first-child .first-letter {
  text-transform: none;
  font-size: 2.8rem;
  height: 2.5rem;
  line-height: 2.9rem;
  text-align: center;
  float: left;
  display: inline-block;
  position: relative;
  top: 0;
  margin-right: 0.15rem;
}
strong:first-child .letter-a { margin-left: -0.3rem; }
strong:first-child .letter-l { margin-left: -0.3rem; }

footer { 
  -webkit-user-select: none;
  -mozuser-select: none;
  user-select: none;
  z-index: 101;
  text-align: center;
  line-height: $line-height-tight;
  background-color: $color-background-secondary;
  color: $color-text-overlay;
  position: relative;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  a {
    color: $color-background-default;
  }
  hr {
    background-color: $color-text-overlay;
  }
}
#footer-container {
  max-width: $width-content-max;
  padding: $line-spacing-larger 1rem $line-spacing-large 1rem;
  margin: 0 auto;
}

#scroll-indicator {
  position: absolute;
  top: 48.5px;
  left: -50px;
  width: 0%;
  height: 2px;
  background-color: $color-brand;
  &:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    right: -50px;
    width: 50px;
    height: 2px;
    background-image: linear-gradient(to right, $color-brand, rgba($color-brand, 0));
  }
}
#footer-nav {
  overflow-x: hidden;
  position: relative;
  // box-shadow: 0 -1px 0 $color-line-faint;
  max-width: $width-content-max;
  margin: 0 auto;
  padding: 0 1rem;
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: 49px;
    left: 0;
    background: $color-line-faint;
    height: 1px;
    width: 100%;
  }
}

#meta {
  position: relative;
  padding-left: 40px;
  .author-picture {
    position: absolute;
    top: 60px;
    left: 0;
    margin: 0;
    display: block;
    width: 30px;
    height: 30px;
    background: $color-link;
    border-radius: 30px;
    @media #{$screen-small} {
      display: none;
    }
  }
  .author,
  .date {
    @media #{$screen-small} {
      display: none;
    }
  }
  .date,
  #read-time {
    color: $color-text-lighter;
    margin-left: 0.5em;
    i {
      margin-right: 0.1em;
    }
  }
  @media #{$screen-small} {
    padding-left: 0;
  }
}

#overlay {
  z-index: -1;
  display: hidden;
  background: rgba(255,255,255,0.85);
  opacity: 0;
  transition-duration: $duration-long;
  transition-property: opacity;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
// .is-showing-menu #overlay,
// .is-showing-subscription #overlay {
//   z-index: 99;
//   display: block;
//   opacity: 1;
// }

#tweet-tooltip {
  cursor: pointer;
  display: block;
  position: absolute;
  width: 46px;
  height: 46px;
  line-height: 46px;
  text-align: center;
  border-radius: 46px;
  box-shadow: 0 8px 24px rgba(0,0,0,0.2);
  font-size: 22px;
  font-family: #{$font-family-system};
  background: $color-background-default;
  left: 0;
  top: 0;
  display: none;
  z-index: 11;
  &:after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    border-top: solid 17px $color-background-default;
    border-left: solid 17px transparent;
    border-right: solid 17px transparent;
    border-bottom: solid 17px transparent;
    position: absolute;
    bottom: -26px;
    left: 6px;
  }
  &:hover {
    color: $color-brand;
  }
}

#debug {
  position: fixed;
  bottom: 10px;
  left: 10px;
  border: solid 1px red;
  z-index: 1000;
  font-size: 12px;
  font-family: monospace;
  padding: 5px;
}






#share {
  transform: translate3d(0, 0, 0);
  position: fixed;
  bottom: 26px;
  right: 26px;
  z-index: 102;
  font-size: 22px;
  width: 46px;
  height: 46px;
  line-height: 46px;
  text-align: center;
  display: block;
  cursor: pointer;
  &:hover {
    animation: excited 0.2s infinite;
    animation-timing-function: ease-out;
    color: $color-brand;
  }
  @media #{$screen-small} {
    right: 16px;
    bottom: 16px;
  }
}
#canvas-indicator {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 101;
  @media #{$screen-small} {
    right: -10px;
    bottom: -10px;
  }
}
#canvas-background {
  z-index: 100;
  content: "";
  display: block;
  position: fixed;
  right: 15px;
  bottom: 15px;
  height: 70px;
  width: 70px;
  border-radius: 70px;
  background: $color-background-default;
  @media #{$screen-small} {
    right: 5px;
    bottom: 5px;
  }
}
.ralign {
  margin-top: $line-spacing-default;
  text-align: right;
}
.calign {
  margin-top: $line-spacing-default;
  text-align: center;
}
.faint {
  color: $color-text-overlay;
  font-size: $font-size-ui;
}
.buy-button {
  max-width: ($width-content-max / 2) - 100px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: #{$font-family-display};
  font-weight: 500;
  display: inline-block;
  text-align: center;
  padding: 0.6em 1em;
  background-color: $color-brand;
  color: $color-background-default;
  background-image: none; 
  background-position: 0 0;
  font-size: $font-size-smaller;
  text-indent: 0;
  position: relative;
  margin: 12px 5px;
  border-radius: 50px;
  text-shadow: 0 -1px 0 rgba(0,0,0,0.2);
  box-shadow: 0 3px 0 rgb(150,68,66), 0 3px 8px rgba(0,0,0,0.2), 0 15px 20px rgba(199,85,85,0.1);
  &:hover {
    // background-image: linear-gradient(to top, darken($color-brand, 0%), lighten($color-brand, 6%));
    color: $color-background-default;
  }
  &:active {
    top: 2px;
    box-shadow: 0 1px 0 darken($color-brand, 10%), 0 1px 3px rgba(0,0,0,0.2);
  }
}
.buy-button span {
  font-size: $font-size-tiny;
  color: rgba(0,0,0,0.5);
  text-shadow: none;
  margin-left: 5px;
}
.buy-button .price {
  border: solid 3px $color-background-default;
  position: absolute;
  top: -0.5rem;
  right: -0.5rem;
  width: 2.2rem;
  height: 2.2rem;
  border-radius: 2.2rem;
  line-height: 2.2rem;
  text-align: center;
  background-color: $color-background-secondary;
  font-size: $font-size-smaller;
  box-shadow: 0 10px 10px rgba(0,0,0,0.05);
}

/* Bookmarks using anchor.js */
.anchorjs-link {
  background-image: none !important;
  left: 0.2rem;
  color: $color-brand;
  display: none;
  &:hover {
    color: $color-text-default;
  }
}

#show-bookmarks {
  cursor: pointer;
  float: right;
  font-family: #{$font-family-system};
  color: $color-brand;
  fill: $color-brand;
  position: relative;
  top: 0.4rem;
  // display: block;
  // position: fixed;
  // right: 1rem;
  // top: 0;
  // cursor: pointer;
  // z-index: 100;
  // width: 50px;
  // height: 20px;
  // line-height: 46px;
  // background-color: $color-brand;
  // text-align: center;
  // font-size: 20px;
  // color: $color-brand;
  &:hover {
    fill: $color-text-default;
    color: $color-text-default;
  }
}
body.is-showingBookmarks {
  #show-bookmarks {
    color: $color-text-default;
    fill: $color-text-default;
  }
  #show-bookmarks-description {
    display: block;
  }
  .anchorjs-link {
    display: inline;
  }
}
#show-bookmarks-description {
  display: none;
  position: fixed;
  right: 1rem;
  top: 2.6rem;
  padding-left: 1rem;
  max-width: 400px;
  font-size: $font-size-smaller;
  line-height: $line-height-tight;
  z-index: 99;
  &:before {
    content: "";
    display: block;
    position: absolute;
    top: -12px;
    right: 12px;
    width: 0;
    height: 0;
    border-top: solid 12px transparent;
    border-left: solid 12px transparent;
    border-right: solid 12px $color-background-secondary;
    border-bottom: solid 12px transparent;
  }
  div {
    color: $color-background-default;
    background-color: $color-background-secondary;
    padding: 1rem;
    border-radius: 5px;
    box-shadow: 0 10px 20px rgba(0,0,0,0.05);
  }
}

#buy {
  text-align: left;
  font-size: $font-size-smaller;
}
