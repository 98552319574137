// $color-brand: rgb(180,84,82);
$color-brand: rgb(180,70,75);
$color-link: lighten(desaturate($color-brand, 20%), 20%);


$color-line-light: rgba(0,0,0,0.2);
$color-line-faint: rgba(0,0,0,0.1);
$color-line-overlay: rgba(255,255,255,0.1);
$color-text-default: rgba(0,0,0,0.85);
$color-text-light: rgba(0,0,0,0.6);
$color-text-lighter: rgba(0,0,0,0.3);
$color-text-overlay: rgba(245,245,255,0.3);
$color-background-default: rgb(240,235,231);
$color-background-secondary: rgb(30,30,40);
$color-background-light: rgb(248,248,248);
$color-white: rgba(255,255,255,0.88);
